<template>
  <aside>
    <header>
      <h2>Import User Fields</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <template v-if="has_errors">
        <div class="alert alert-danger">
          <strong>Oops, we ran into an issue with your import.</strong> Some of the email addresses in your CSV were unable to be matched to users on your HiThrive team.
        </div>
        <pre>{{emails_not_found.join('\n')}}</pre>
      </template>
      <template v-else>
        <div class="setting-row">
          <div class="title">
            <h4>CSV File</h4>
            <p>
              Upload a CSV file to map to user fields.
            </p>
          </div>

          <div class="fields">
            <div class="form-group">
              <input type="file" ref="csv_file" v-on:change="handleFile" />
            </div>

            <div class="form-group">
              <label><input type="checkbox" v-model="includes_headers" /> CSV file includes headers?</label>
            </div>
          </div>
        </div>

        <div class="setting-row">
          <div class="title">
            <h4>Map fields</h4>
            <p>
              Map your CSV columns to HiThrive fields.
            </p>
          </div>

          <div class="fields">
            <div class="available-fields">
              <a href="#" v-if="!hasField('birthdate')" @click.prevent="addField('Birthdate', 'birthdate')">Birthdate</a>
              <a href="#" v-if="!hasField('anniversary')" @click.prevent="addField('Start date', 'anniversary')">Start date</a>
              <a href="#" v-for="field in custom_fields" :key="`custom_field_selector_${field.id}`" @click.prevent="addField(field.label, field.id)">{{field.label}}</a>
              <a href="#" @click.prevent="addField('', 'custom')">Custom <i class="far fa-plus"></i></a>
            </div>
            
            <table class="table">
              <tr v-for="(field, index) in fields" :key="`field_map_${index}`">
                <td width="15">
                  <a href="#" v-if="field.id !== 'email'" class="delete" @click.prevent="removeField(index)">
                    <i class="fas fa-trash"></i>
                  </a>
                </td>
                <td>
                  <select class="form-control form-control-sm" v-model="field.csv_index">
                    <option :value="-1" disabled>Select a CSV column</option>
                    <option v-for="(col, colindex) in preview_cols" :value="col.index" :key="`${index}_col_opt_${colindex}`">
                      {{col.label}}
                    </option>
                  </select>
                </td>
                <td width="25">
                  <i class="fas fa-long-arrow-right"></i>
                </td>
                <td>
                  <input type="text" v-if="field.id === 'custom'" class="form-control form-control-sm" v-model="field.label" placeholder="Enter custom field name..." />
                  <template v-else>
                    {{field.label}}
                  </template>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </template>
    </main>

    <footer v-if="!has_errors">
      <button class="btn btn-sm btn-green" :class="{'loading': loading}" @click="attemptImport">Import fields</button>
    </footer>
  </aside>
</template>

<script>
import Papa from 'papaparse'

export default {
  computed: {
    canImport() {
      return this.fields.find(f => f.id === 'email') !== -1;
    },
    preview_cols() {
      if( !this.csv_results ) return null;

      //if( this.includes_headers ) return this.csv_results[0];

      return _.map(this.csv_results[0], v => {
        return {
          index: this.csv_results[0].indexOf(v),
          label: v
        };
      })
    },
    custom_fields() {
      if(this.profile_fields === null) return [];

      return this.profile_fields.filter(f => !this.hasField(f.id))
    }
  },
  data() {
    return {
      includes_headers: true,
      fields: [
        {
          id: 'email',
          label: 'Email address',
          csv_index: -1
        }
      ],
      csv_results: null,
      loading: false,
      profile_fields: null,
      has_errors: false,
      emails_not_found: []
    }
  },
  mounted() {
    this.populateFields()
  },
  methods: {
    async populateFields() {
      const {profile_fields} = await this.$api.Team.get_profile_fields()
      this.profile_fields = profile_fields;
    },
    hasField(field) {
      return this.fields.findIndex(f => f.id === field) > -1 ? true : false;
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    addField(label, field) {
      this.fields.push({
        id: field,
        label,
        csv_index: -1
      })
    },
    handleFile() {
      const file = this.$refs.csv_file.files[0]

      Papa.parse( file, {
        preview: 10,
        complete: (results) => {
          this.csv_results = results.data;
        }
      } )
    },
    async attemptImport() {
      const file = this.$refs.csv_file.files[0];

      if( file.size > 3000000 ) { //3MB file size limit
        this.$message.error('The maximum file size for a CSV is 3MB.')
        return;
      }
      this.loading = true;

      //upload
      const fileData = new FormData();
      fileData.append('file', file);
      fileData.append('map_data', JSON.stringify({
        columns: this.fields,
        has_headers: this.includes_headers
      }))

      const resp = await this.$api.Team.import_user_fields( fileData )
      this.loading = false;

      if(resp.emails_not_found.length > 0) {
        //have some errors
        this.has_errors = true;
        this.emails_not_found = resp.emails_not_found;
      }
      else {
        this.$toast.success('User fields have been imported.');
        this.$emit('done', 'IMPORTED_FIELDS');
      }
    },
    close() {
      this.$emit('done')
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  .delete {
    font-size: 12px;
    color: $red;
  }
}
.available-fields {
  display: flex;
  flex-wrap: wrap;
  margin: -3px -3px 15px;

  > a {
    display: block;
    margin: 3px 3px;
    
    background: $light;
    color: $muted-text;
    font-weight: 700;
    border-radius: 100em;
    padding: 5px 10px;
    line-height: 12px;
    font-size: 12px;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      background: darken($light, 10%);
    }
  }
}
</style>